<template>
  <div class="page-main-box pt-24 pr-16">
    <!-- 有数据 -->
    <div class="pl-16">
      <!-- 搜索头 -->
      <search-header :schemaData="schema" @getDataList="handleFormSearch" />

      <!-- 列表 -->
      <div class="table-height" :style="{ height: tableHeight + 'px' }">
        <p-table id="account-table" :dataSource="tableDataSource" height="auto">
          <p-t-column field="card_no" min-width="242" title="账号/卡号" />
          <p-t-column field="company_name" min-width="336" title="企业名称">
            <template v-slot="{ row }">
              <span>{{
                row.company_name?.length > 18
                  ? row.company_name?.substr(0, 18) + "..."
                  : row.company_name
              }}</span>
            </template>
          </p-t-column>
          <p-t-column
            field="account_type_desc"
            min-width="196"
            title="账户类型"
          />
          <p-t-column
            field="account_service_desc"
            min-width="270"
            title="服务项"
          />
          <p-t-column
            field="balance"
            min-width="122"
            title="余额（元）"
            align="right"
          >
            <template v-slot="{ row }">
              <span>{{ (row.balance / 100).toFixed(2) }}</span>
            </template>
          </p-t-column>
          <p-t-column width="127" title="操作" fixed="right">
            <template v-slot="{ row }">
              <Button
                v-if="
                  row.account_type !== 'CONSUMER' &&
                    $store.getters.roles.includes('Recharge')
                "
                type="link"
                @click="handleRecharge(row)"
              >
                充值
              </Button>
              <Button
                v-if="
                  row.account_type === 'CONSUMER' &&
                    $store.getters.roles.includes('Recharge')
                "
                type="link"
                disabled
              >
                充值
              </Button>
              <Button type="link" @click="handleToDetail(row)">
                账户明细
              </Button>
            </template>
          </p-t-column>
        </p-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRaw, onMounted } from "vue";
import { Button } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import { useRouter } from "vue-router";
import { useAccountQueryApi } from "@/apis/financial";
import {
  useEnumAccountTypeApi,
  useEnumServiceApi,
  useCompanyEnumApi
} from "@/apis/enums";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import useSearchForm from "@/hooks/UseSearchForm";
import useSearch from "@/hooks/useSearch";
import useTableHeight from "@/hooks/useTableHeight";
import useDebounce from "@/hooks/useDebounce";

export default {
  name: "account",
  components: { Button },
  setup() {
    const router = useRouter();
    const accountQueryApi = useAccountQueryApi();
    const enumAccountTypeApi = useEnumAccountTypeApi();
    const enumServiceApi = useEnumServiceApi();
    const companyEnumApi = useCompanyEnumApi();

    // table高度
    const tableHeight = ref();

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    let schemaList = searchHeaderOptions;
    let enumeration = searchHeaderOptions.find(v => v.field === "company_id");

    const handleSearch = useDebounce(async value => {
      const [, res] = await $await(
        companyEnumApi({ name: value, page: 1, size: 100 })
      );
      useSearch(searchHeaderOptions, res, "company_id", false);
    }, 500);
    handleSearch();

    (async () => {
      let [, account] = await $await(enumAccountTypeApi());
      schemaList = useSearch(searchHeaderOptions, account, "account_type");
      let [, service] = await $await(
        enumServiceApi({ code: "billing_account_service" })
      );
      schemaList = useSearch(searchHeaderOptions, service, "account_service");
      enumeration.props.onSearch = handleSearch;
    })();

    const { filter, handleFormSearch } = useSearchForm(schemaList);

    Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(accountQueryApi(data));
        return useTableDataRecords(res);
      }
    });

    // 充值操作
    const handleRecharge = row => {
      router.push({
        path: "/financial/recharge",
        query: { row: JSON.stringify(row) }
      });
    };
    // 打开服务配置弹窗
    const handleToDetail = row => {
      router.push({
        path: "/financial/account-detail",
        query: {
          id: row.id,
          company_name: row.company_name,
          account_type_desc: row.account_type_desc,
          account_service_desc: row.account_service_desc
        }
      });
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      tableHeight,
      handleRecharge,
      handleToDetail,
      tableDataSource,
      handleFormSearch,
      schema: searchHeaderOptions
    };
  }
};
</script>

<style lang="less" scoped>
#serviceOrderBox {
  position: relative;
  overflow: hidden;
}
</style>
