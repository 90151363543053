/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    field: "company_id",
    label: "企业名称",
    type: "select-data-search",
    defaultValue: null,
    iw: 160,
    props: {
      placeholder: "请输入企业名称"
    },
    options: []
  },
  {
    type: "select",
    field: "account_type",
    label: "账户类型",
    iw: 160,
    defaultValue: null,
    options: []
  },
  {
    type: "select",
    field: "account_service",
    label: "服务项",
    iw: 160,
    defaultValue: null,
    options: []
  }
];
